/* @override 
	http://assets.tuplo.test/7.4/css/footers/tk-footerBasic.css */
	
footer {
	background-color: var(--colorGray900);
	color: white;
}

footer .tk-row {
	padding: 40px var(--rowPadding) 0;
}

footer .tk-logo {
	display: block;
	max-width: 150px;
	margin-bottom: 80px;
}

footer p {
	font-size: 0.8rem;
	text-align: center;
	color: var(--colorGray500);
}