/* @override 
	http://assets.tuplo.test/7.4/css/controls/tk-button.css */
	
.tk-button {
	display: inline-block;
	text-decoration: none;
	background-color: black;
	color: white;
	font-weight: 500;
	padding: 6px 16px;
	font-size: 0.9rem;
}

input[type=submit].tk-button {
	padding: 12px 16px;
}

.tk-button.tk-medium {
	padding: 10px 20px;
	font-size: 1rem;
}

.tk-button.tk-transparent {
	background-color: transparent;
}