/* @override 
	http://assets.tuplo.test/7.4/css/alerts/tk-pageAlerts.css */
.pageAlertWrapper {
	padding-right: 30px;
	padding-left: 30px;
	padding-top: 50px;
	margin-left: auto;
	margin-right: auto;
}

.pageAlert {
	position: relative;
	max-width: 600px;
	border-radius: 5px;
	padding: 26px 20px 26px 28px;
	border-style: solid;
	border-width: 1px;
	margin: 30px auto;
	margin-bottom: 0px;
	font-weight: 400;
	line-height: 130%;
}

.pageAlert h2 {
	margin-bottom: 6px;
}

.pageAlert.success {
	background-color: #f1f9f0;
	border-color: #7cbc76;
}

.pageAlert.success a {
	color: #265d28;
}

.pageAlert.success h2 {
	color: #41883a;
}

.pageAlert.error {
	background-color: #FFF3F2;
	border-color: #f98a89;
}

.pageAlert.error a {
	color: #5d2626;
	text-decoration: underline;
}

.pageAlert .closeButton {
	position: absolute;
	width: 22px;
	top: 7px;
	right: 7px;
}

.pageAlert .closeButtonSVG {
	width: 21px;
	height: auto;
	cursor: pointer;
}

.pageAlert.success .closeButtonSVG {
	fill: #41883a;
}

.pageAlert.error .closeButtonSVG {
	fill: #b11210;
}

.pageAlert.error h2 {
	color: #b11210;
}

.pageNotFound {
	padding: 50px;
}

.pageNotFound h1 {
	margin-bottom: 20px;
}

.pageNotFound .myTitle {
	margin-bottom: 10px;
}