/* @override 
	http://assets.tuplo.test/7.4/css/images/tk-imageFade.css */
	
.tk-imageFade {
	position: relative;
}

.tk-imageFade > div:first-child {
	background: linear-gradient(0deg, rgba(255,255,255,1) 60px, rgba(255,255,255,0) 80%);
	height: 50%;
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
}