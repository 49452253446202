/* @override 
	http://assets.tuplo.test/7.4/css/base/tk-input.css */
:root {
	--inputFontSize: 1.0rem;
	--labelFontSize: 0.9rem;
	--labelFontColor: inherit;
	--labelFontColorFloat: #555;
	--labelFontColorFloat: #606060;
	--labelFontColorError: #C20503;
	--placeHolderFontColor: #979797;
	--placeHolderFontColorError: #E68888;
	--inputFontColorDisabled: #979797;
	--inputFontColorError: #C20503;
	--inputFontColorInvalid: #C20000;
	--inputBackgroundColor: white;
	--inputBackgroundColorError: #FFEEEE;
	--inputBorderRadius: 3px;
	--inputBorderColor: #cccccc;
	--inputBorderColor: #DFDFDF;
	--inputBorderColorFocused: dodgerBlue;
	--inputBorderColorError: #C20000;
	--inputShadowColorFocused: dodgerBlue;
	--inputShadowColorError: #FFD0D0;
	
	--radioMarkInnerColor: white;
	--radioMarkOuterColor: lightGray;
	--radioMarkOuterColorSelected: dodgerBlue;
	--radioBackgroundColor: #F2F2F2;
	--radioBackgroundColorSelected: #D0E6FE;
	--radioBoxBackgroundColor: lightGray;
	--radioFontColorSelected: #1467C9;
	
	--checkboxMarkInnerColor: white;
	--checkboxMarkOuterColor: lightGray;
	--checkboxMarkOuterColorSelected: dodgerBlue;
	--checkboxBorderRadius: 3px;
}

/* @group Base */
input[type=submit] {
	border: none;
}

input[type=text],input[type=password],input[type=email],input[type=search],input[type=tel], select, textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	-ms-appearance: none;
	appearance: none;
	font-size: 0.9rem;
}

input[type=text], input[type=email], input[type=password], input[type=tel] {
	border: 1px solid var(--inputBorderColor);
	border-radius: var(--inputBorderRadius);
	width: 100%;
	padding: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

input::placeholder, textarea::placeholder {
	color: var(--placeHolderFontColor);
}

input.tk-error::placeholder, textarea.tk-error::placeholder {
	color: var(--placeHolderFontColorError);
}

textarea {
	border: 1px solid var(--inputBorderColor);
	border-radius: var(--inputBorderRadius);
	width: 100%;
	padding: 10px;
}

input:focus, textarea:focus {
	outline: none;	
	border-color: var(--inputBorderColorFocused);
	box-shadow: 0 0 0 1px var(--inputShadowColorFocused);
}

input[type=text].tk-error, input[type=password].tk-error, input[type=email].tk-error, input[type=tel].tk-error, textarea.tk-error, select.tk-error {
	color: var(--inputFontColorError);
	border-color: var(--inputBorderColorError);
	box-shadow: 0 0 3px 1px var(--inputShadowColorError);
	background-color: var(--inputBackgroundColorError);
}

input[type=file].tk-forPanel {
	font-size: 0.9rem;
	padding: 10px;
	background-color: ghostWhite;
}

button {
	border: none;
}

textarea {
	width: 100%;
	min-height: 150px;	
	padding: 10px;
	border: 1px solid var(--inputBorderColor);
}

/* @end */

/* @group Select */
select {    
	background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyNDAuODExIDI0MC44MTEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDI0MC44MTEgMjQwLjgxMTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwYXRoIGlkPSJFeHBhbmRfTW9yZSIgZD0iTTIyMC4wODgsNTcuNjY3bC05OS42NzEsOTkuNjk1TDIwLjc0Niw1Ny42NTVjLTQuNzUyLTQuNzUyLTEyLjQzOS00Ljc1Mi0xNy4xOTEsMAoJCWMtNC43NCw0Ljc1Mi00Ljc0LDEyLjQ1MSwwLDE3LjIwM2wxMDguMjYxLDEwOC4yOTdsMCwwbDAsMGM0Ljc0LDQuNzUyLDEyLjQzOSw0Ljc1MiwxNy4xNzksMEwyMzcuMjU2LDc0Ljg1OQoJCWM0Ljc0LTQuNzUyLDQuNzQtMTIuNDYzLDAtMTcuMjE1QzIzMi41MjgsNTIuOTE1LDIyNC44MjgsNTIuOTE1LDIyMC4wODgsNTcuNjY3eiIvPjwvc3ZnPg==") right 10px center no-repeat #ffffff;
	background-size: 10px;
	font-family: inherit;
	cursor: inherit;
	line-height: inherit;
	padding: 7px 40px 7px 12px;
	border: 1px solid var(--inputBorderColor);
	border-radius: var(--inputBorderRadius);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

select::-ms-expand {
    display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
}

select:focus {
	outline: none;
	border: 1px solid var(--inputBorderColorFocused);
	box-shadow: 0 0 0 1px var(--inputShadowColorFocused);
}

select:invalid {
	color: var(--inputFontColorInvalid);
}

select:disabled {
	color: var(--inputFontColorDisabled);
}

/* @end */

/* @group Input Box */
.tk-inputBox {
	margin-top: 10px;
	margin-bottom: 20px;
	text-align: left;
}

.tk-inputBox label {
	color: var(--labelFontColor);
	font-size: var(--labelFontSize);
	font-weight: 400;
}

.tk-inputBox .tk-twoInputs td {
	vertical-align: top;
}

.tk-inputBox table.tk-twoInputs input {
	width: 98%;
}

.tk-inputBox table.tk-twoInputs td:last-child {
	padding-left: 2%;
}
/* @end */

/* @group Float Label */

.tk-floatLabel {
	display: flex;
	position: relative;
	margin-bottom: 12px;
}
.tk-floatLabel input {
	transition: all 0.3s ease;
	padding-bottom: 10px;
	padding-top: 23px;
}

.tk-floatLabel label {
	position: absolute;
	top: 11px;
	left: 10px;
	color: var(--labelFontColorFloat);
	font-weight: 400;
	font-size: var(--labelFontSize);	
	font-size: 0.75em;
	transition: all 0.3s ease;
	pointer-events: none; /* makes input clickable by unblocking label */
	transform: translateY(-12px); /* do this bc :not is not super compatible*/
	opacity: 0.7;
}

.tk-floatLabel input:placeholder-shown {
	padding-bottom: 12px;
	padding-top: 13px;
	padding-bottom: 17px;
	padding-top: 16px;
}

.tk-floatLabel input:placeholder-shown + label {
	opacity: 0.0;
	transform: translateY(0px);
}

.tk-floatLabel select.tk-error, .tk-floatLabel input.tk-error + label {
	color: var(--labelFontColorError);
}

/* @end */

/* @group Pretty Checkbox */
.tk-prettyCheckbox {
	display: none;
}

/* Checkbox Setup */
.tk-prettyCheckbox + label {
  position: relative;
  display: flex;
  line-height: 140%;
  transition: color 2050ms ease;
  cursor: pointer;
  text-align: left;
}

/*  Checkbox Size Position */
.tk-prettyCheckbox + label > span {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  margin-right: 9px;
  width: 18px;
  height: 18px;
  background: transparent;
  border: 1px solid var(--checkboxMarkOuterColor);
  border-radius: var(--checkboxBorderRadius);
  background-color: var(--checkboxMarkInnerColor);
  cursor: pointer;
  transition: all 200ms ease;
}

/* Checkbox Color */
.tk-prettyCheckbox:checked + label > span {
  border: 9px solid var(--checkboxMarkOuterColorSelected);
}

.tk-prettyCheckbox + label > span::before {
	content: '';
	position: absolute;
	width: 3px;
	height: 5px;
	top: 8px;
	left: 7px;
	border-right: 2px solid transparent;
	border-bottom: 2px solid transparent;	    
	border-color: var(--checkboxMarkInnerColor);	
	transform: rotate(45deg);
	transform-origin: 0% 100%;
	opacity: 0;
	transition: all 200ms ease 100ms;
}

.tk-prettyCheckbox:checked + label > span::before {
	opacity: 100%;	
	top: 0px;
	left: 3.5px;
	width: 6px;
	height: 10px;
}
/* @end */

/* @group Radio Group */
.tk-radioGroup input[type="radio"] {
	display: none;
}

.tk-radioGroup label {
	display: flex;
	align-items: center;
	cursor: pointer;
	transition: all 0.2s ease;
}

.tk-radioGroup input[type="radio"]+label::before { 
	content: '';
	display: inline-block;
	width: 16px;
	height: 16px;
	border-radius: 8px;
	background-color: var(--radioMarkInnerColor);
	border: 1.5px solid var(--radioMarkOuterColor);
	transition: all 0.4s ease;
	margin-right: 8px;
}

.tk-radioGroup input[type="radio"]:checked+label::before {
	border: 5px solid var(--radioMarkOuterColorSelected);
    }
    
.tk-radioGroup.hideOptions input[type="radio"]+label {
	/* when in store pickup we hide options and force cc address */
	display: none;
}

/* @end */

/* @group Radio Group Color */

.tk-radioGroup.tk-separatedStyle label {
	border-radius: var(--inputBorderRadius);
	padding: 10px;
	background-color: var(--radioBackgroundColor);
	margin-bottom: 6px;
}

.tk-radioGroup.tk-separatedStyle input[type="radio"]:checked+label {
	background-color: var(--radioBackgroundColorSelected);
}

.tk-radioGroup.tk-togetherStyle {
	border: 1px solid var(--inputBorderColor);
	border-radius: var(--inputBorderRadius);
}

.tk-radioGroup.tk-togetherStyle input[type="radio"]+label {
	padding: 10px;
	border-top: 1px solid var(--inputBorderColor);
}

.tk-radioGroup.tk-togetherStyle input[type="radio"]+label:first-of-type {
	border-top: none;
}

.tk-radioGroup.tk-togetherStyle input[type="radio"]:checked+label {
	color: var(--radioFontColorSelected);
}

.tk-radioGroupContentBox {
	padding: 15px 15px 5px; /* 5px on bottom because divs inside pad bottom */
	border-top: 1px solid var(--inputBorderColor);
	background-color: var(--radioBoxBackgroundColor));
}

.tk-radioGroupContentBox.last {
	border-radius: 0 0 var(--inputBorderRadius) var(--inputBorderRadius);
}

/* @end */

