/* @override 
	http://assets.tuplo.test/7.4/css/sections/tk-sectionFiftyFifty.css */

.tk-sectionFiftyFifty > .tk-row {
	display: flex;
	padding-top: var(--sectionPadding);
	padding-bottom: var(--sectionPadding);
}

.tk-sectionFiftyFifty > .tk-row > div {
	width: 50%;
}

@media only screen and (max-width: 768px) {
.tk-sectionFiftyFifty > .tk-row {
	flex-direction: column;
}
.tk-sectionFiftyFifty > .tk-row > div {
	width: 100%;
}
}