/* @override 
	http://assets.tuplo.test/7.4/css/sections-sub/tk-groupThirds.css */
	
.tk-groupThirds {
	display: flex;
	justify-content: space-between;
}

.tk-groupThirds > div {
	width: 33.33%;
}

.tk-groupThirds + .tk-groupThirds {
	margin-top: 40px;
}

.tk-groupThirds > div {
	margin-left: 40px;
}

.tk-groupThirds > div:first-child {
	margin-left: 0;
}

@media only screen and (max-width: 768px) {
.tk-groupThirds {
	flex-direction: column;
	align-items: center;
}

.tk-groupThirds + .tk-groupThirds {
	margin-top: 0;
}

.tk-groupThirds > div {
	width: 100%;
}

.tk-groupThirds > div {
	margin-left: 0;
	margin-bottom: 40px;
}
}
