/* @override 
	http://assets.tuplo.test/7.4/css/sections-sub/tk-groupHalf.css */

.tk-groupHalf {
	display: flex;
}

.tk-groupHalf > div {
	width: 50%;
}

@media only screen and (max-width: 768px) {
.tk-groupHalf {
	flex-direction: column;
}

.tk-groupHalf > div {
	width: 100%;
}
}