/* @override 
	http://assets.tuplo.test/7.4/css/sections-sub/tk-cardHangIcon.css */
	
.tk-cardHangIcon {
	display: flex;
}

.tk-cardHangIcon b {
	font-weight: 600;
}

.tk-cardHangIcon .tk-icon {
	margin-right: 10px;
}