/* @override 
	http://assets.tuplo.test/7.4/css/heros/tk-heroHal.css */
	
.tk-heroHal {
	display: flex;
}

.tk-heroHal > div:first-child {
	margin: 50px 0 0 auto;
	padding: 50px var(--rowPadding);
	width: calc(var(--siteWidth) / 2 - 100px);
}

.tk-heroHal-logo {
	max-width: 200px;
	margin: 30px 0;
}

.tk-heroHal h1 {
	margin: 30px 0;
}

.tk-heroHal p {
	margin: 30px 0 50px;
}

.tk-heroHal > div:last-child {
	width: calc(50% + 100px);
	overflow: hidden;
	max-height: 700px;
}

.tk-heroHal .tk-mainImage {
	margin: 80px 0 0 0;
	width: 1000px;
	height: auto;
}

@media only screen and (max-width: 768px) {
.tk-heroHal {
	display: block;
	text-align: center;
}

.tk-heroHal-logo {
	width: 70%;
}

.tk-heroHal > div:first-child {
	margin: 30px auto 0 auto;
	max-width: 500px;
	width: 100%;
}

.tk-heroHal > div:last-child {
	width: 100%;
	max-height: 430px;
	text-align: center;
}

.tk-heroHal .tk-mainImage {
	margin: 20px 0 0 5%;
	width: 800px;
	height: auto;
}
}
