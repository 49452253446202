/* @override 
	http://assets.tuplo.test/7.4/css/heros/tk-heroPotzi.css */
	
.tk-heroPotzi {
	background: #1f2937 url('') no-repeat center center;
	background-size: cover;
	text-align: center;
}

.tk-heroPotzi .tk-row {
	padding: 140px var(--rowPadding) 100px;
	max-width: 600px;
	}

.tk-heroPotzi .tk-logo {
	display: block;
	margin: 0 auto 100px auto;
	max-width: 70%;
	width: 250px;
}

.tk-heroPotzi .tk-button {
	margin: 30px;
	font-weight: 600;
}

.tk-heroPotzi  p {
	color: var(--colorGray200);
}