#tk-widthInfo, #widthInfo {
	background-color: rgba(0,0,0,0.7);
	color: white;
	border: 1px solid black;
	border-radius: 2px;
	padding: 3px;
	z-index: 100000;
	position: fixed;
	right: 0;
	bottom: 0;
	line-height: 16px;
}