/* @override 
	http://morsecoding.test/css/min-mc-main.css */

/* @group Imports */
@import url("http://assets.tuplo.test/7.4/css/base/normalize.css");
@import url("http://assets.tuplo.test/7.4/css/base/tk-row.css");
@import url("http://assets.tuplo.test/7.4/css/base/tk-input.css");
@import url("http://assets.tuplo.test/7.4/css/base/tk-memoryInfo.css");
@import url("http://assets.tuplo.test/7.4/css/base/tk-widthInfo.css");
@import url("http://assets.tuplo.test/7.4/css/colors/tk-colorGray.css");
@import url("http://assets.tuplo.test/7.4/css/colors/tk-colorBlue.css");
@import url("http://assets.tuplo.test/7.4/css/colors/tk-colorRed.css");
@import url("http://assets.tuplo.test/7.4/css/filters/tk-filterBlue.css");
@import url("http://assets.tuplo.test/7.4/css/filters/tk-filterWhite.css");
@import url("http://assets.tuplo.test/7.4/css/headers/tk-headerBasic.css");
@import url("http://assets.tuplo.test/7.4/css/footers/tk-footerBasic.css");
@import url("http://assets.tuplo.test/7.4/css/heros/tk-heroHal.css");
@import url("http://assets.tuplo.test/7.4/css/heros/tk-heroPotzi.css");
@import url("http://assets.tuplo.test/7.4/css/sections/tk-sectionFlexGrow.css");
@import url("http://assets.tuplo.test/7.4/css/sections/tk-sectionHalfOpen.css");
@import url("http://assets.tuplo.test/7.4/css/sections/tk-sectionFiftyFifty.css");
@import url("http://assets.tuplo.test/7.4/css/sections-sub/tk-group.css");
@import url("http://assets.tuplo.test/7.4/css/sections-sub/tk-groupHalf.css");
@import url("http://assets.tuplo.test/7.4/css/sections-sub/tk-groupThirds.css");
@import url("http://assets.tuplo.test/7.4/css/sections-sub/tk-card.css");
@import url("http://assets.tuplo.test/7.4/css/sections-sub/tk-cardHangIcon.css");
@import url("http://assets.tuplo.test/7.4/css/images/tk-imageFade.css");
@import url("http://assets.tuplo.test/7.4/css/controls/tk-button.css");
@import url("http://assets.tuplo.test/7.4/css/alerts/tk-pageAlerts.css");

/* @end */

/* @group Base */
:root {
	--sectionPadding: 100px;
	--rowPadding: 40px;
	--sectionPaddingMobile: 80px;
	--rowPaddingMobile: 25px;
	--siteWidth: 1200px;
	--boxShadowUI: 0 20px 40px rgba(0, 0, 0, 0.17);
	--borderUI: 1px solid var(--colorGray300);
	--mobileBreak: 768px;
}

html,body {
	min-height: 100vh;
	margin: 0;
	font-size: 16px;
	font-weight: 300;
	font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif;
	line-height: 180%;
	color: var(--colorGray900);
}

html {
	box-sizing: border-box;
	background-color: white;
}

body {
	display: flex;
	flex-direction: column;
}

*, *::before, *::after {
	box-sizing: inherit;
}

a {
	color: var(--colorBlue500);
}

button {
	border: none;
	background-color: transparent;
	padding: 0;
}

h1,h2,h3,h4,h5,h6 {
	margin: 0;
	line-height: 100%;
	word-wrap: break-word;
}

h1 {
	font-size: 3.0rem;
}

h2 {
	font-size: 1.9rem;
}

h3 {
	font-size: 1.5rem;
}
/* @end */

/* @group Site */

.tk-row {
	margin: 0 auto;
	padding-left: var(--rowPadding);
	padding-right: var(--rowPadding);
}

.tk-section {
	padding: 100px 0;
}

.tk-groupCards + .tk-groupCards {
	margin-top: 20px;
}

.tk-card {
	text-align: left;
}

.tk-cardHangIcon .tk-icon {
	margin-top: 4px;
}

.tk-subtitle {
	color: var(--colorBlue500);
	font-weight: 600;
}

.tk-icon {
	filter: var(--filterBlue500);
}

.tk-pitchText {
	font-weight: 400;
	font-size: 1.1rem;
	color: var(--colorGray500);
	margin: 40px auto;
	max-width: 650px;
}

.tk-button {
	background-color: var(--colorBlue500);
}

.tk-buttonPlain {
	text-decoration: none;
	font-weight: 500;
}

.mc-sectionIcon {
	width: 60px;
	height: auto;
}

.mc-light {
	background-color: var(--colorGray50);
}

.mc-light .tk-pitchText {
	color: var(--colorGray600);
}

.mc-dark {
	background-color: var(--colorGray900);
	color: white;
}

.mc-dark .tk-subtitle {
	color: var(--colorBlue400);
}

.mc-dark .tk-pitchText {
	color: var(--colorGray300);
}

.mc-dark .tk-icon {
	filter: var(--filterBlue400);
}

/* @end */

/* @group Header */
header .tk-logo img {
	width: 42px;
	height: auto;
}
/* @end */

/* @group Footer */
footer .tk-logo {
	width: 150px;
	height: auto;
}
/* @end */

/* @group Home */
#mc-homeHero {
	background-image: url('/images/bg-tuplo-meeting.jpg');
	color: white;
}

#mc-homeHero img.tk-logo {
	width: 280px;
	height: auto;
	position: relative;
	left: -5px;
	margin-top: 20px;
	margin-bottom: 90px;
}

#mc-homeRetailSolutions {
	text-align: center;
}

#mc-homeRetailSolutions .tk-imageFade {
	max-width: 100%;
	padding: 40px; /* compensate for shadow */
	/*max-height: 700px;*/
	overflow: hidden;
	margin: 0 auto;
}

#mc-homeRetailSolutions .tk-mainImage {
	width: 600px;
	height: auto;
	border-radius: 0.4rem;
	border: var(--borderUI);
	box-shadow: var(--boxShadowUI);
}

#mc-homePartner > div:first-child {
	background: url('/images/agency.jpg') no-repeat left bottom;
	background-size: cover;
	min-height: 300px;
}

#mc-homePartner .tk-button {
	margin-top: 60px;
}

/* @end */

/* @group Tuplo */
#mc-tuploHero.tk-heroHal {
	color: white;
	background: var(--colorGray900) url('/images/tuplo-cpu-bg.svg') no-repeat center top;
	background-size: 1500px auto;
	/*border-top: 1px solid var(--colorGray950);*/
}

#mc-tuploHero.tk-heroHal p {
	color: var(--colorGray300);
}

#mc-tuploHero .tk-subtitle {
	color: white;
	font-weight: 400;
}

#mc-tuploNavigate {
	text-align: center;
	padding: var(--sectionPadding) 0;
}

#mc-tuploNavigate .tk-mainImage {
	width: 100%;
	max-width: 800px;
	height: auto;
	margin: 40px 0 60px;
}

#mc-tuploSpeedy.tk-sectionHalfOpen {
	flex-direction: row-reverse;
}

#mc-tuploSpeedy .tk-group {
	margin-top: 40px;
}

#mc-tuploSpeedy .tk-mainImage {
	width: 100%;
	max-width: 320px;
	height: auto;
	box-shadow: 0 0 10px #000;
	display: block;
	margin: 0 auto;
}

#mc-tuploSpeedy.tk-sectionHalfOpen > div:first-child {
	display: flex;
	align-items: center;
	background: var(--colorGray900) url('/images/tuplo-cpu-bg.svg') no-repeat left center;
	background-size: 750px;
	padding: 80px 40px;
}

#mc-tuploSpeedy.tk-sectionHalfOpen > div:first-child > div {
	max-width: calc(var(--siteWidth) / 2);
	width: 100%;
	text-align: center;
}

#mc-tuploSpeedy.tk-sectionHalfOpen > div:last-child {
	padding-left: var(--rowPadding);
	padding-right: var(--rowPadding);
}

#mc-tuploChartCourse {
	text-align: center;
	padding: var(--sectionPadding) 0;
}

#mc-tuploChartCourse .tk-imageFade {
	max-width: 100%;
	padding: 40px; /* compensate for shadow */
	max-height: 700px;
	overflow: hidden;
	margin: 0 auto;
}

#mc-tuploChartCourse .tk-mainImage {
	width: 1000px;
	height: auto;
	border-radius: 0.4rem;
	border: var(--borderUI);
	box-shadow: var(--boxShadowUI);
}

#mc-tuploChartCourse .tk-cardHangIcon {
	text-align: left;
}

#mc-tuploSimple {
	text-align: center;
	padding: var(--sectionPadding) 0;
}

#mc-tuploSimple .tk-cardsThree {
	margin-top: 60px;
}

#mc-tuploDeveloper > div:first-child {
	background: #f5f5f5 url('/images/developers.webp') no-repeat center center;
	background-size: cover;
}
#mc-tuploDeveloper .tk-button {
	margin-top: 60px;
}
/* @end */

/* @group Jobs */
.mc-jobPost {
	margin-top: 20px;
	border: 1px solid var(--colorGray200);
	padding: 23px 20px 20px 25px;
	background-color:  var(--colorGray50);
}
/* @end */

/* @group About */
#mc-aboutMain > .tk-row {
	justify-content: space-between;
	gap: 30px;
}
#mc-aboutMain > .tk-row > div:last-child {
	background: #f5f5f5 url('/images/oregon-sign.jpg') no-repeat center center;
	background-size: cover;
	border-radius: 15px;
	min-height: 400px;
}
/* @end */

/* @group Contact */
#mc-contactForm p {
	margin: 30px 0 60px;
}

#mc-contactForm #contactForm .tk-button {
	margin-top: 20px;
}
/* @end */

@media only screen and (max-width: 768px) {
.tk-row {
	padding-left: var(--rowPaddingMobile);
	padding-right: var(--rowPaddingMobile);
}
#mc-tuploHero.tk-heroHal {
	background-position: center 350px;
}

#mc-tuploSpeedy.tk-sectionHalfOpen > div:first-child {
	background-position: center -340px;
	padding-top: 180px;
	padding-bottom: 0px;
	justify-content: center;
}

#mc-tuploSpeedy.tk-sectionHalfOpen > div:last-child {
	margin: 0 auto;
}

#mc-tuploSpeedy.tk-sectionHalfOpen .tk-mainImage {
	width: 80%;
}
}