/* @override 
	http://assets.tuplo.test/7.4/css/sections/tk-sectionHalfOpen.css */
	
.tk-sectionHalfOpen {
	display: flex;
}

.tk-sectionHalfOpen > div {
	width: 50%;
	padding: var(--sectionPadding) var(--rowPadding);
}

.tk-sectionHalfOpen > div:last-child {
	max-width: calc(var(--siteWidth) / 2);
}

@media only screen and (max-width: 768px) {
.tk-sectionHalfOpen {
	display: block;
}

.tk-sectionHalfOpen > div {
	width: 100%;
	padding: var(--sectionPaddingMobile) var(--rowPaddingMobile);
}

.tk-sectionHalfOpen > div:last-child {
	max-width: 100%;
}
}