/* @override 
	http://assets.tuplo.test/7.4/css/sections-sub/tk-card.css */
	
.tk-card b {
	font-weight: 600;
}

.tk-card > div {
	display: flex;
}

.tk-card .tk-icon {
	height: auto;
	margin-right: 10px;
}