/* @override 
	http://assets.tuplo.test/7.4/css/headers/tk-headerBasic.css */

header {
	background-color: var(--colorGray900);
	color: white;
}

header .tk-row {
	padding-top: 20px;
	padding-bottom: 20px;
}

header .tk-row > div{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

header .tk-logo img {
	display: block;
}

#tk-menu a {
	display: inline-block;
	text-decoration: none;
	padding: 2px 20px;
	color: white;
	font-weight: 600;
	font-size: 14px;
	line-height: 1;
}

#tk-menuMobile {
	display: none;
	position: absolute;
	background-color: white;
	background-color: var(--colorGray900);
	color: white;
	width: 100%;
	height: 100vh;
	padding: 20px;
	z-index: 1000;
}

#tk-menuMobile a {
	color: white;
	text-decoration: none;
	display: block;
	font-weight: 600;
	padding: 10px 0;
}

#tk-menuMobileButton img {
	display: none;
	filter: var(--filterWhite);
}

#tk-menuMobileButtonClose {
	display: none;
	filter: var(--filterWhite);
}

@media only screen and (max-width: 768px) {
header .tk-row {
	padding-top: 12px;
	padding-bottom: 12px;
}

#tk-menu {
	display: none;
}

#tk-menuMobileButton img {
	display: block;
}
}